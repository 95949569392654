// ==================================================
//  Function: モバイル判定
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return (intFrameWidth <= 767) ? true : false;
}


// ==================================================
//  Event: メニューボタンクリック
// ==================================================
const menu = document.getElementById("menu");
const menuActiveClass = "js_headerMenu-active";
const menuButton = document.getElementById("menuButton");
function menuToggle() {
  menu.classList.toggle(menuActiveClass);
}
menuButton.addEventListener('click', function(event) {
  menuToggle();
});


// ==================================================
//  Event: ページが完全に読み込まれた
// ==================================================
const jsLoadedAnimation = 'jsLoadAnimation';
const jsLoadedAnimationLoaded = 'jsLoadAnimation-loaded';
const jsLoadedAnimationBody = 'jsLoadAnimationBody';
const jsLoadedAnimationBodyLoaded = 'jsLoadAnimationBody-loaded';
document.addEventListener('DOMContentLoaded', (event) => {

  const jsLoadedAnimationElement = document.querySelector("." + jsLoadedAnimation);
  const jsLoadedAnimationBodyElement = document.querySelector("." + jsLoadedAnimationBody);

  // ==================================================
  //  Function: ローディングアニメーション
  // ==================================================
  const loadedAnimation = () => {
    jsLoadedAnimationBodyElement.classList.add(jsLoadedAnimationBodyLoaded);
    jsLoadedAnimationElement.classList.add(jsLoadedAnimationLoaded);
  }
  setTimeout(loadedAnimation, 1.0*500);

  // ==================================================
  //  Function: アンカーリンクのスムーススクロール
  // ==================================================
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  let positionAdjust = 0;
  if (is_mobile()) {
    positionAdjust = 80;
  }
  anchorLinksArr.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const targetId = link.hash;
      if (targetId !== '') {
        const targetElement = document.querySelector(targetId);
        const targetOffsetTop = (window.pageYOffset + targetElement.getBoundingClientRect().top) - positionAdjust;
        window.scrollTo({
          top: targetOffsetTop,
          behavior: "smooth"
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

      // Menuの処理
      if (menu.classList.contains(menuActiveClass) == true) {
        menuToggle();
      }
    });
  });
});


// ==================================================
//  Event: スクロールイベント
// ==================================================
const header = document.querySelector("header");
let scrollTicking = false;
document.addEventListener('scroll', (event) => {

  if (!scrollTicking) {
    requestAnimationFrame(() => {
      scrollTicking = false;

      // 追従ヘッダーの横スクロール対応
      header.style.left = -window.scrollX + 'px';
    });
    scrollTicking = true;
  }
},
{ passive: true });